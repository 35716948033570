// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.
@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");

.navbar {
  background: #6069e1;
}

.ff-k2d {
  font-family: "K2D", sans-serif;
}

.navbar-brand {
  font-family: "K2D", sans-serif;
  font-size: 1.6rem;
}

.profil {
  .profil-thumbnail {
    height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.sidebar .galeri {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid rgba($color: #152c5b, $alpha: 0.1);
  .column-image {
    padding: 5px;
    object-fit: cover;
    object-position: center;
    img {
      height: 100px;
      width: 100%;
    }
  }
}

.post-list {
  .blist-item {
    & + .blist-item {
      margin-top: 20px;
    }
    .post-thumbnail {
      height: 215px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    a .title-post {
      transition: color 0.1s ease;

      &:hover {
        color: #008aff;
      }
    }
  }

  .sidebar .category {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgba($color: #152c5b, $alpha: 0.1);
    &__header {
      text-align: center;
      font-size: 1.1rem;
      font-weight: 600;
      color: #152c5b;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: "";
        width: 3px;
        height: 50px;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    &__content {
      .category__list {
        list-style: none;
        padding: 5px;
        margin: 0;

        .category__item {
          margin: 10px 0 0 0;
          padding-bottom: 3px;
          font-family: "Cabin", sans-serif;

          &:hover::before {
            color: #6069e1;
          }
          &:not(:last-child) {
            border-bottom: 2px solid rgba($color: #152c5b, $alpha: 0.1);
          }
          .category__link {
            color: #152c5b;
            font-weight: 700;

            &:hover {
              color: #6069e1;
            }
          }

          &::before {
            font-family: "Font Awesome 5 Free";
            content: "\f054";
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            font-weight: 600;
            color: rgba($color: #152c5b, $alpha: 0.2);
            margin-right: 10px;
            transition: color 0.1s ease;
          }
        }
      }
    }
  }
}

.berita {
  .card {
    .card-body {
      padding: 0;

      img {
        width: 100%;
        padding: 0.5px;
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
      }
      h5 a {
        color: #008aff;
        font-family: "Cabin", sans-serif;

        &:hover {
          color: #152c5b;
        }
      }
      p {
        color: #152c5b;
      }
    }
  }
}

.berita-list {
  background-color: #fbfdff;

  #map {
    height: 500px;
  }
  .link-berita {
    h5 {
      font-family: "Cabin", sans-serif;
    }
    &:hover h5,
    &:active h5,
    &:focus h5 {
      color: #008aff;
    }

    &:hover .caption,
    &:active .caption,
    &:focus .caption {
      background-color: #fff !important;
      transform: translateY(-20px);
    }
  }
  .card {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    .card-body {
      padding: 0;
      position: relative;

      .berita-thumbnail {
        height: 300px;
        border-radius: 0.75rem;
        opacity: 1;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      h5 {
        font-size: 1.4rem;
      }

      .badge {
        position: absolute;
        top: 15px;
        left: 30px;
        font-size: 0.7rem;
      }

      .caption {
        position: relative;
        width: 100%;
        background-color: #fdfeff;
        border-radius: 0.75rem;
        overflow: hidden;
        font-family: "Cabin", sans-serif;
        .desc-admin .desc-time {
          font-size: 0.8rem;
        }
      }
    }
  }

  .sidebar .category {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgba($color: #152c5b, $alpha: 0.1);
    &__header {
      text-align: center;
      font-size: 1.1rem;
      font-weight: 600;
      color: #152c5b;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: "";
        width: 3px;
        height: 50px;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    &__content {
      .category__list {
        list-style: none;
        padding: 5px;
        margin: 0;

        .category__item {
          margin: 10px 0 0 0;
          padding-bottom: 3px;
          font-family: "Cabin", sans-serif;

          &:hover::before {
            color: #6069e1;
          }
          &:not(:last-child) {
            border-bottom: 2px solid rgba($color: #152c5b, $alpha: 0.1);
          }
          .category__link {
            color: #152c5b;
            font-weight: 700;

            &:hover {
              color: #6069e1;
            }
          }

          &::before {
            font-family: "Font Awesome 5 Free";
            content: "\f054";
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            font-weight: 600;
            color: rgba($color: #152c5b, $alpha: 0.2);
            margin-right: 10px;
            transition: color 0.1s ease;
          }
        }
      }
    }
  }
}

.berita-list + footer .shape-container svg {
  fill: #fbfdff;
}

.layanan {
  .card {
    position: relative;
    transition: transform 0.2s ease;
    .card-body {
      font-family: "K2D", sans-serif;
    }

    &:hover {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      transform: translateY(-5px);

      &::before {
        content: "";
        height: 5px;
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        background-color: #6069e1;
      }
    }
  }
}

.breadcrumb-page {
  .breadcrumb {
    border: none;
    padding: 0;
  }
}

.detail-post {
  .detail-thumbnail.big {
    height: 500px;
    border-radius: 0.35rem;
    opacity: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .detail-thumbnail.smll {
    height: 140px;
    border-radius: 0.35rem;
    opacity: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .deskripsi {
    text-align: justify;
  }
}

@media screen and (max-width: 400px) {
  .layanan .card-body h5 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 620px) {
  .navbar-brand {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 991px) {
  .hamburger {
    display: block;
    position: relative;
    transition: all 0.3s ease;

    .line {
      position: absolute;
      height: 3px;
      background-color: #fff;
      border-radius: 2px;
      transition: all 0.5s ease;
    }

    .line-top {
      top: -7px;
      left: -25px;
      width: 15px;
    }
    .line-mid {
      top: 1px;
      left: -25px;
      width: 30px;

      &.translate {
        width: 15px;
        transform: translateX(0px);
      }
    }
    .line-bot {
      top: 9px;
      left: -10px;
      width: 15px;

      &.translate {
        transform: translateX(-15px);
      }
    }

    &.translate {
      transform: translateX(-180px);
    }
  }

  .navbar-collapse {
    position: fixed;
    right: 0;
    top: 0;
    width: 200px;
    min-height: 100vh;
    background: linear-gradient(
      rgb(67, 50, 85),
      rgb(92, 69, 121),
      rgb(119, 88, 159),
      rgb(146, 108, 199),
      rgb(175, 129, 240)
    ) !important;

    transform: translateX(200px);
    transition: all 0.3s ease;

    &.show {
      transform: translateX(0);
    }
  }
  .navbar-nav {
    display: flex;
    padding: 50px 20px;
  }

  .navbar-nav .nav-link,
  .navbar-nav .nav-item .dropdown-item {
    color: #fff !important;
  }

  section,
  .navbar-brand {
    transition: transform 0.3s ease;
  }

  section.side-active,
  .navbar-brand.side-active {
    transform: translateX(-120px);
  }

  .navbar-nav-mobile .nav-item {
    padding: 0 15px;
    border: 1px solid rgba($color: #fff, $alpha: 0.3);
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgba($color: #ffffff, $alpha: 0.1);
  }
}

footer {
  a > .footer-brand {
    color: #fff;

    &:hover {
      color: #008aff;
    }
  }
}

#galeri .column-image {
  object-fit: cover;
  height: 300px;
  overflow: hidden;
  margin-bottom: 30px;
}

.column-image img {
  width: 100%;
}

.lg-backdrop.in {
  opacity: 0.8;
}

.lg-outer .lg-thumb-outer {
  background-color: rgba($color: #000, $alpha: 0.4);
}
